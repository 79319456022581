import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import wxRoute from './module/WXApp'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/disc',
    name: 'disc',
    component: () => import(/* webpackChunkName: "about" */ '../views/disc.vue'),
  },
  {
    path: '/WeiXin/bindUser',
    name: 'wxIndex',
    meta: { title: '微信绑定' },
    component: () => import(/* webpackChunkName: "about" */ '../views/WeiXin/index.vue'),
  },
  {
    path: '/WeiXin/phoneBindUser',
    name: 'phoneBindUser',
    meta: { title: '微信手机号绑定' },
    component: () => import(/* webpackChunkName: "about" */ '../views/WeiXin/phoneBindUser.vue'),
  },
  ...wxRoute,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    // 判断是否有标题
    document.title = to.meta.title + ''
  }
  console.log(to.query.token)
  to.query.token && localStorage.setItem('token', JSON.stringify(to.query.token))
  next()
})

export default router

import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router'

const wxRoute: any[] = [
  {
    path: '/oiWX/billCompleteState',
    component: () => import('../../views/oiWX/analysis/billCompleteState.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '接单完成情况'
      next()
    },
  },
  {
    path: '/oiWX/ShipmentCompleteState',
    component: () => import('../../views/oiWX/analysis/ShipmentCompleteState.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '发货完成情况'
      next()
    },
  },
  {
    path: '/oiWX/strategyProductState',
    component: () => import('../../views/oiWX/analysis/strategyProductState.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '战略产品分析'
      next()
    },
  },
  {
    path: '/oiWX/wolrdDistribute',
    component: () => import('../../views/oiWX/analysis/wolrdDistribute.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '洲际分析'
      next()
    },
  },
  {
    path: '/oiWX/customAnalysis',
    component: () => import('../../views/oiWX/analysis/customAnalysis.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '客户分析'
      next()
    },
  },
  {
    path: '/oiWX/invItemType',
    component: () => import('../../views/oiWX/analysis/invItemType.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '产品类别分析'
      next()
    },
  },
  {
    path: '/oiWX/ProductAnalysis',
    component: () => import('../../views/oiWX/analysis/ProductAnalysis.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '产品分析'
      next()
    },
  },
  {
    path: '/oiWX/TeamGrowth',
    component: () => import('../../views/oiWX/analysis/TeamGrowth.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '团队成长'
      next()
    },
  },
  {
    path: '/oiWX/OMRegisterTaskState',
    component: () => import('../../views/oiWX/analysis/OMRegisterTaskState.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '登记分析'
      next()
    },
  },
  {
    path: '/oiWX/freightanalysis',
    component: () => import('../../views/oiWX/analysis/freightanalysis.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '运费分析'
      next()
    },
  },
  {
    path: '/oiWX/coseAccount',
    component: () => import('../../views/oiWX/analysis/coseAccount.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '费用分析'
      next()
    },
  },
  {
    path: '/oiWX/RawMaterialAnalysis',
    component: () => import('../../views/oiWX/analysis/RawMaterialAnalysis.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '询盘分析'
      next()
    },
  },
  {
    path: '/oiWX/interestanalysis',
    component: () => import('../../views/oiWX/analysis/interestanalysis.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '利息分析'
      next()
    },
  },
  {
    path: '/oiWX/costgrossprofit',
    component: () => import('../../views/oiWX/analysis/costgrossprofit.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '毛利分析'
      next()
    },
  },
  {
    path: '/oiWX/receivableaging',
    component: () => import('../../views/oiWX/analysis/receivableaging.vue'),
    beforeEnter: (to: any, from: any, next: any) => {
      document.title = '账龄分析'
      next()
    },
  },
]

export default wxRoute

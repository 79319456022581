import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

const app = createApp(App)

import vant from 'vant'
// 2. 引入组件样式
import 'vant/lib/index.css'
import '@/style/base.scss'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 3. 注册你需要的组件
app.use(router)

app.use(vant)
// app.use(Grid);
// app.use(GridItem);

// app.use(Form);
// app.use(Field);
// app.use(CellGroup);
// app.use(Space);
// app.use(BackTop);
app.use(ElementPlus, {
  locale: zhCn,
})
// app.use(Button).use(CountDown).use(Col).use(Row).use(RadioGroup).use(Radio);
app.mount('#app')

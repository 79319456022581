<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<style lang="scss">
div {
  box-sizing: border-box;
}

.statusTitleRadio {
  display: flex;
  justify-content: flex-start;
  padding: 8px 0 8px 8px;
}
.optionsBox {
  display: flex;
  justify-content: space-between;
  padding: 8px 8px 0 8px;
}
.el-checkbox {
  margin-right: 8px !important;
}
:deep(.el-checkbox) {
  margin-right: 8px !important;
}
:deep(.el-radio) {
  margin-right: 8px !important;
}
.el-radio {
  margin-right: 8px !important;
}
</style>
